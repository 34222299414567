<template>
  <div class="Userhl_videogl Userhl_rightbox">
    <ul class="Userhl_baomingrwtype">
      <li
        v-for="(item, index) in bigtype"
        :key="index"
        @click="bigetypeclick(item.id,charge)"
      >
        {{ item.name }}
      </li>
    </ul>
    <ul class="Userhl_baomingrwzt">
      <li
        v-for="(item, index) in moneytype"
        :key="index"
        @click="bigetypeclick(classid,item.id)"
      >
        {{ item.name }}
      </li>
      <li class="Userhl_baomingrwzt_add" @click="addheji">新建合集</li>
    </ul>
    <ul class="Userhl_zuopinul">
      <li
        class="Userhl_zuopinulli"
        v-for="(item, index) in hejiall"
        :key="index"
      >
        <div class="Userhl_zuopinulliimg" @click="videolink(item.p_id)">
          <img :src="item.title_pic" alt="" />
          <!-- 环境设计 -->
          <div class="jiaoyu_ulliimg_titlesmall" v-if="item.p_type == 23"></div>
          <div class="jiaoyu_ulliimg_title" v-if="item.p_type == 23">
            <p>{{ item.title }}</p>
            <p>包装设计</p>
          </div>
          <!-- 环境设计 -->
          <!-- 工业 -->
          <div
            class="jiaoyu_ulliimg_titlesmall jiaoyu_ulliimg_titlesmallgy"
            v-if="item.p_type == 24"
          ></div>
          <div
            class="jiaoyu_ulliimg_title jiaoyu_ulliimg_titlegy"
            v-if="item.p_type == 24"
          >
            <p>{{ item.title }}</p>
            <p>包装设计</p>
          </div>
          <!-- 工业 -->
          <!-- 视觉传达 -->
          <div
            class="jiaoyu_ulliimg_titlesmall jiaoyu_ulliimg_titlesmallsj"
            v-if="item.p_type == 25"
          ></div>
          <div
            class="jiaoyu_ulliimg_title jiaoyu_ulliimg_titlesj"
            v-if="item.p_type == 25"
          >
            <p>{{ item.title }}</p>
            <p>包装设计</p>
          </div>
          <!-- 视觉传达 -->
        </div>
        <p class="Userhl_zuopinulliname">{{ item.title }}</p>
        <p class="Userhl_zuopinullitype">
          课程数量 <span>（{{ item.video_num }}）</span>
        </p>
        <div class="Userhl_zuopinullibtm">
          <div class="Userhl_zuopinullibtmlvideo">
            <img :src="item.userpic" alt="" />
            <span>{{ item.username }}</span>
          </div>
          <div class="Userhl_zuopinullibtmr">
            更新时间：{{ item.create_time }}
          </div>
        </div>
        <div
          class="Userhl_zuopinulli_3add"
          @click="addvideo(item.p_id, item.p_type)"
        >
          上传视频
        </div>
        
      </li>
    </ul>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".Userhl_baomingrwtype li", function () {
  $(this).css({
    color: "#4FC6FF",
    "border-bottom": "2px solid #4FC6FF",
  });
  $(this).siblings().css({
    color: "#333",
    border: "none",
  });
  $(this).siblings().eq(0).css({
    color: "#999",
  });
});
$(document).on("click", ".Userhl_baomingrwzt li", function () {
  $(this).css({
    color: "#4FC6FF",
    "border-bottom": "2px solid #4FC6FF",
  });
  $(this).siblings().css({
    color: "#333",
    border: "none",
  });
  $(this).siblings().eq(0).css({
    color: "#999",
  });
});
export default {
  components: {},
  data() {
    return {
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      bigtype: [
        {
          name: "专业分类",
          id: "",
        },
        {
          name: "全部",
          id: "",
        },
        {
          name: "环境艺术",
          id: "22",
        },
        {
          name: "产品设计",
          id: "23",
        },
        {
          name: "视觉传达设计",
          id: "24",
        },
      ],
      moneytype: [
        {
          name: "收费情况",
          id: "",
        },
        {
          name: "全部",
          id: "",
        },
        {
          name: "免费课程",
          id: "1",
        },
        {
          name: "付费课程",
          id: "2",
        },
      ],
      userinfo: {},
      hejiall: [],
      page:"1",
      limit:"100",
      classid:"",
      charge:"",
    };
  },
  methods: {
    addheji() {
      this.$router.push({ path: "/Sjfabu" });
    },
    addvideo(id, p_type) {
      this.$router.push({
        path: "/Userhl_favideo_s",
        query: { hejiid: id, typeid: p_type },
      });
    },
    videolink(id) {
      this.$router.push({
        path: "/jiaoyu_heji",
        query: { data: id },
      });
    },
    bigetypeclick(classid,charge) {
      this.hejiall=[];
      var that=this;
      this.classid=classid;
      this.charge=charge;
      this.$axios({
        headers: { "X-CSRF-TOKEN": this.userinfo.token },
        url: "/api/getMentorVideoList",
        method: "post",
        data: {
          page: that.page,
          limit: that.limit,
          classid,
          charge,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.hejiall = res.data.result.list;
        }else{
          that.$message(res.data.messages);
        }
      });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.bigetypeclick();
  },
};
</script>
